import {PREFIX} from "./ConstantService";
import {UrlService} from "./UrlService";
import axios from "axios";
import {GlobalService} from "./GlobalService";

export const ExcelService = {
  async csv(entity, filters = null) {
    let response = null;
    let queryString=UrlService.setQueryString(filters)
    try {
      switch (entity) {
        case 'ACCIDENT INCIDENT':
          response = await axios.get(PREFIX + 'csv/accident-incidents',{responseType: 'blob'})
          break;
        case 'ACCIDENT_INCIDENT_EVENT':
          response = await axios.get(PREFIX + 'csv/accident-incidents-per-event'+queryString,{responseType: 'blob'})
          break;
        default:
          response = await axios.get(PREFIX + 'csv/accident-incidents',{responseType: 'blob'})
          break;
      }
      const filename= entity + ' LIST - ' + Date.now()
      if(response?.data) {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data],{type:'text/csv'}));
        const link = document.createElement('a');
        link.setAttribute('href', fileUrl);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', filename);
        link.click();
      }
    }
    catch (errors) {
      GlobalService.handleError(errors, 'GENERATE CSV SERVICE')
      return false
    }


  }
}
